@import 'settings/glob';
@import 'tools/glob';
// @import 'base/glob';
// @import 'objects/glob';
// @import 'components/glob';
// @import 'utilities/glob';
// @import 'vendors/glob';


/* OVERRIDES */
div.promotion-right {
	margin-left: 10px;
	@include breakpoint(small) {
		margin-left: 0;
	}
}

div.overlay {
	left: 10px;
	width: calc(100% - 10px) !important;

	.promotion-left & {
		left: 0;
	}
	@include breakpoint(small) {
		left: 0;
		width: 100% !important;
	}
	@include breakpoint(xsmall) {
		display: none;
	}
}

.label-ghost, .btn-ghost {
	font-size: 18px;
	font-weight: 700;
	text-transform: uppercase;
	border: 1px solid #333f48;
	padding: 10px 15px;
	display: block;
	max-width: 554px;
	margin: 45px auto 0;
	transition: all 0.3s ease-in-out;
	@include breakpoint(xsmall) { 
		font-size: 14px;
	}
	&.ghost-black {
		border: 1px solid #333f48;
		color: #333f48;
		&:hover {
			background: #333f48;
			color: #fff;
		}
	}
	&.ghost-white {
		border: 1px solid #fff;
		color: #fff;
		&:hover {
			background: #fff;
			color: #333f48;
		}

	}
	.fa {
		margin-left: 10px;
	}
	&:hover {
		text-decoration: none;
	}
}

// Helpers
.no-padding-xs {
	@include breakpoint(xsmall) {
		padding: 0 !important;
		margin: 0;
	}
}



/* PAGE HEADER */
.page-top-header {
	background: #333f48;
}


/* START PAGE */

.search-area {
	color: #fff;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: bottom right;
	text-align: center;
	height: 600px;
	min-height: 600px;
	.search-inner-content {
		max-width: 690px;
		margin: 0 auto;
		position: relative;
		top: 50%;
		transform: translateY(-62%);
		@include breakpoint(xsmall) { 
			transform: translateY(-80%);

		}
		h1 {
		font-size: 50px;
		margin-bottom: 20px;
		margin-top: 0;
		@include breakpoint(xsmall) { 
			font-size: 24px;
			max-width: 85%;
			margin: 0 auto 20px;

		}

	}
		h2 {
			font-size: 15px;
			text-transform: none;
			max-width: 500px;
			margin: 0 auto 30px;
			@include breakpoint(xsmall) { 
				line-height: 1.3em;
				max-width: 85%;
				font-size: 14px;
			}
		}

		.article-search {
			min-height: 80px;
			border: none;	
			display: block;
			margin: 0 auto;
			padding: 0 40px;
			min-width: 690px;
			border-radius: 6px !important; 
			font-size: 22px;
			color: #333;
			@include breakpoint(xsmall) { 
				font-size: 14px;
				min-width: 90%;
				max-width: 90%;
				padding: 0 10px;
				min-height: 60px;
			}
		}
		a.btn {
			clear: both;
			margin-top: 30px;
			background: #333f48;
			padding:10px 35px;
			@include breakpoint(xsmall) { 
				font-size: 14px;
				min-width: 90%;
				max-width: 90%;
				min-height: 45px;
				
			}
			i {
				color: #fff ;
			}
		}
	}
}
div.product-grid {
	padding-left: 10px !important;
	padding-right: 10px !important;

	.col-sm-3 {
		padding: 0 10px;
	}
	.product-listitem {
		border: 1px solid #ddd;
		text-align: center;
		margin-bottom: 30px;
		min-height: 350px;
		max-height: 350px;
		&:hover {
			border: 1px solid #ffd500;
		}

		&.product-listitem-intro {
			background: #ffcd00;
			text-align: left;
			padding: 70px 25px 25px;

			@include breakpoint(small) {  
				padding: 15px 15px 15px;
				min-height: auto;
				max-height: 280px;
				h2 {
					font-size: 18px;
				}
			}
			@include breakpoint(xsmall) {  
				padding: 35px 25px 25px;
				min-height: auto;
				max-height: 280px;
			}
		}
		a {
			display: block;
			position: relative;
			min-height: 350px;
			overflow-x: hidden;
			&:hover {
				text-decoration: none;
			}
		}
		img {
			position: absolute;
			bottom: 55px;
			left: 50%;
			transform: translateX(-50%);
			max-width: 95%;
		}
		h4 {
			font-weight: 400;
			position: absolute;
			bottom: 10px;
			left: 0;
			right: 0;


		}
	}

}

.promotion-left-text {
	@include breakpoint(xsmall) { 
		color: #000;
	}
}
.promotion-left h2, .promotion-right h2, .promotion-bottom h2 {
	font-size: 20px;
}




/* STEPS */
.parts-guide-wrapper {
	
	.parts-guide-panel {
		padding-bottom: 40px;
		color:#333f48;
		&.dark-header {
			color: #fff;
		}
		// min-height: 100vh;
		&:before {
			content: '';
			position: absolute;
			width: 100%;
			height: 360px;
			
			border-bottom: 1px solid #ddd;
			z-index: -1;
		}
		&.light-header {
			&:before {
				background: #f2f2f2;
			}
		}
		&.dark-header {
			&:before {
				background: #333f48;
			}
		}
		&.yellow-header {
			&:before {
				background: #ffd500;
			}
		}

		.parts-header-section {
			text-align: center;
			max-width: 690px;
			margin: 0 auto 30px;
			h1 {
				font-size: 50px;
				font-weight: 700;
				@include breakpoint(small) {  
					font-size: 24px;
				}

			}
			h2 {
				font-size: 15px;
				font-weight: 700;
				line-height: 1.4em;
				@include breakpoint(small) {  
					font-size: 14px;
				}

			}
		}
		.parts-image-container, .parts-table-container{
			min-height:100px;
			// max-height: 344px;
			border:1px solid #ddd; 
			background: #fff;
		}
		.parts-image-container {
			text-align: center;

			@include breakpoint(xsmall) { 
				// margin-top: 30px;
			} 
			img{
				max-height: 342px;
				padding: 20px 0;
			}
		}
		.parts-image-caption {
			text-align: center;
			
			p {
				&:first-child {
					margin: 10px 0 0 0;
				}
				margin: 0;
			}
			@include breakpoint(small) { 
				margin-bottom: 30px;
			}
		}

		.parts-table-container {
			overflow: auto;
			// max-height: 344px;
			@include breakpoint(xsmall) { 
				border: none;
				border-top: 2px solid #000;
				overflow: visible;
				max-height: none;
			}
			header {
				position: absolute; 
				width: calc(100% - 32px); 
				background:#f8f8f8;
				border-bottom: 1px solid #ddd;
				z-index: 99;
				padding: 0;
				@include breakpoint(small) { 
					top: 0;
					border-top: 1px solid #ddd;
				}
				div {
					border-left: 1px solid #ddd;
					min-height: 43px;
					line-height: 1.2em;
					padding: 13px 0;
					text-align: center;
					font-weight: 700;
					&:first-child {
						border:none;
						padding-left: 5%;
					}
					&:last-child {
						padding-right: 5%;
					}
				}
			}
			.parts-table {
				padding-top: 45px;
				@include breakpoint(xsmall) { 
					padding-top: 0;
				}
				.row {
					border-bottom:1px solid #ddd;
					margin: 0 auto 0 auto;
					padding: 0;
					max-width: 95%;
					@include breakpoint(xsmall) { 
						max-width: 100%;
						padding: 10px 15px;
					}
					&:last-child {
						border: none;
						@include breakpoint(xsmall) { 
							border-bottom:1px solid #ddd;
						}
					}
				}
				.parts-table-contentitem {
					height: 69px; 
					display: table-cell;
					text-align: center;
					@include breakpoint(xsmall) {
						text-align: left;
						height: auto;
						display:block;
						width: 60%;
						padding: 0;
						span {
							display: inline-block;
							font-weight: 700;
							margin-right: 3px;
						}
					}
					
					&:first-child {
						text-align: right;
						padding-right: 0;
						@include breakpoint(xsmall) {
							float: right;
							width: 40%;
							height: 69px;
						}
					}
					p, a {
						position: relative;
						top: 50%;
						transform: translateY(-50%);
						font-size: 16px;
						@include breakpoint(xsmall) {
							font-size: 14px;
							transform: none;
							top: 0;
							margin-bottom: 3px;
						}
					}
					a {
						top: 50%;
						transform: translateY(-50%);
						width: 95%;
					}
				}				
			}
		}
		// Step 2
		.grid-item-wrapper {
			text-align: center;
			.grid-item-content {
				position:relative;
				background: #fff;
				border: 1px solid #ddd;
				padding: 15px;

				.info-icon, .close-icon {
					color:#333f48; 
					font-size:1.2em; 
					position:absolute; 
					right: 35px; 
					top: 25px;
					z-index: 999;

					@include breakpoint(medium) {
						font-size: 1.6em;
						right: 15px;
						top: 12px;
						&:hover {
							cursor: pointer;
						}
					}
				}
				.close-icon {
					opacity: 0;
					z-index: 998;
					font-size: 2.2em;
					color: #fff;
					transition: opacity 0.2s 0.4s ease-in-out;
					&.active {
						opacity: 1;
						z-index:9999;
					}
				}
				.info-overlay {
					position: absolute; 
					top:0; 
					left:0; 
					background: rgba(0,0,0,0.7); 
					height: 100%;
					width: 100%;
					padding: 40px;
					text-align: left;
					opacity: 0;
					transition: opacity 0.2s ease-in-out;
					color: #fff;
					
					&.active {
						opacity: 1;
					}


					.info-overlay-content {
						position: relative;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);
						max-width: 540px;
					}
				}
				
				&:hover {
					@include breakpoint(medium-min) {
						.info-overlay {
							opacity: 1;
						}
					}	
				}
			}

			.btn {
				margin-top: 40px;
				padding: 10px 55px;
				@include breakpoint(small) { 
					margin-top: 20px;
					margin-bottom: 20px;
				}
			}
		}

		// Step 3
		div.hotspot-container {
			min-height: 560px;
			@include breakpoint(xsmall) { 
				min-height: 400px;
				margin-bottom: 30px;
			}
			h4 {
				text-align: center;
				max-width: 454px;
				margin: 40px auto 0;
				text-transform: none;
				font-size: 18px;
				@include breakpoint(xsmall) {  
					margin-top: 15px;
				}
			}
			.hotspot-link-wrapper {
				max-width: 454px;
				margin: 80px auto 0;
				text-align: left;
				@include breakpoint(xsmall) {  
					margin-top: 45px;
				}
			}
			.hotspot-link {
				text-align: center;
				background: #ffd500;
				width: 96px;
				height: 96px;
				line-height: 96px;
				display: inline-block;
				border-radius: 50%;
				margin: 0 14px 14px 0;
				transition: all 0.2s ease-in-out;
				text-decoration: none;
				@include breakpoint(small) { 
					width: 80px;
					height: 80px;
					line-height: 80px;
					margin: 0 18px 18px 0;
				}
				@include breakpoint(xsmall) { 
					width: 80px;
					height: 80px;
					line-height: 80px;
					margin: 0 3px 41px 0;
				}
				&:hover, &:active {
					text-decoration: none;
					background: #333f48;
					color: #fff;
				}
			}
		}
		// Step 4
		.results-wrapper {
			border:1px solid #ddd; 
			background: #f2f2f2; 
			padding: 30px 15px;

			@include breakpoint(xsmall) { 
				margin-top: 30px;
			}


			.results-image-wrapper {
				border:1px solid #ddd; 
				background: #fff; 
				padding: 15px;
				text-align: center;
				img {
					max-height: 300px;
				}

			} 
			.results-info-wrapper{
				
				h4 {
					font-size: 18px;
					margin-top: 10px;
					@include breakpoint(xsmall) { 
						font-size: 14px;
					}
				}
				.btn {
					margin-top: 50px;
				}
				p {
					font-size: 18px;
					margin-bottom: 0;
					@include breakpoint(xsmall) { 
						font-size: 14px;
					}
				}
				a {
					
					max-width: 220px;
					@include breakpoint(xsmall) { 
						max-width: 100%;
					}

					&.btn {
						max-width: 100%;
						white-space: normal;
						@include breakpoint(xsmall) { 
							width: 100%;
							// padding: 10px 20px;
						}
					}
					
					&.readmore-link {
						display: block;
						margin-top: 20px;
						font-weight: 700;
						margin-left: 10px;
						text-decoration: underline;
						@include breakpoint(xsmall) {
							text-align: center;	
						}
						i {
							margin-left: 3px;
						}
					}
				}
			}
			.results-extrainfo-wrapper {
				background: #ddd;
			    padding: 20px;
			    text-align: center;
			    @include breakpoint(xsmall) { 
					margin-top: 30px;
				}
			    h4, p {
			    	font-size: 15px;
			    }
			    h4 {
			    	margin-top: 0;
			    }
			    img {
			    	margin: 20px auto;
			    }
			}
			.mobile-btn-wrapper {
				margin-top: 30px;
				padding: 0 15px;
				a {
					margin-bottom: 5px;
				}
				.readmore-link {
					display: block;
					font-weight: 700;
					margin-left: 10px;
					margin-top: 10px;
					text-decoration: underline;
					@include breakpoint(xsmall) {
						text-align: center;	
					}
					i {
						margin-left: 3px;
					}
				}
			}
		}
	}
}

