// Use this file to define your font families.
// For example:
@font-face {
  font-family: 'Font-Name';
  src: url('../fonts/font-name.eot');
  src: url('../fonts/font-name.eot?#iefix') format('embedded-opentype'),
       url('../fonts/font-name.woff') format('woff'),
       url('../fonts/font-name.ttf') format('truetype'),
       url('../fonts/font-name.svg#font-name') format('svg');
  font-weight: normal;
  font-style: normal;
}
